<section class="box">
  <div class="container wrapper">
  <div class="title">
    <h3>
     <span>About Us</span>
    </h3>
  </div>
  <div class="contain-wrapper">
    <mat-list class="list-group">
      <mat-list-item >
        <mat-card >
          <mat-card-content class="atarget1">
            <span class="card-contain" style="font-weight: bolder;">{{title}}</span> is the component of marketing that uses the Internet and online based digital technologies such as desktop computers, mobile phones, Application and other digital media and platforms to <span style="color: #0099FF;">promote products and services</span>. Digitalized Social Interactions Made Simpler.
          </mat-card-content>
          <div class="divider">
            <span></span>
          </div>
          <mat-card-content class="atarget2">
            We are on a mission to help your business grow significantly and organically with the latest technologies and minds at work. We've worked hard days and nights to help brands and their businesses grow.
          </mat-card-content>
        </mat-card>
      </mat-list-item>
    </mat-list>
  </div>
</div>
</section>
