import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.scss']
})
export class WhatsappChatComponent implements OnInit {
whatsapp:string = '+91'+environment.client.mobile;
waref:string = 'https://wa.me/'+this.whatsapp;
title:string = environment.client.mobile;
showBox = false;
@ViewChild('msgbox') wcmsg!:ElementRef<HTMLElement>;
  constructor(
    private maticon: MatIconRegistry
  ) {

   }
  ngOnInit(): void {

  }



  msgshow () {
      this.wcmsg.nativeElement.style.transform = 'scale(1)';
  }
  msghide () {
    this.wcmsg.nativeElement.style.transform = 'scale(0)';
  }
}
