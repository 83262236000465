import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';

// import Swiper core and required modules
declare var anime:any;

// install Swiper modules

@Component({
  selector: 'app-infobox',
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class InfoboxComponent implements OnInit,AfterViewInit {
  title = environment.title;
  observe = new IntersectionObserver((entries)=>{
    entries.map((entry)=> {
      if(entry.isIntersecting) {
        if(entry.target.classList.value.match('atarget1')) {
          anime({
            targets:[entry.target],
            delay:10,
            scale: [0,1], // -> from '28px' to '100%',
            easing: 'easeInOutQuad',
          })
        }
        if(entry.target.classList.value.match('atarget2')) {
          anime({
            targets:[entry.target],
            delay:60,
            rotateY: ['360deg','0deg'], // -> from '28px' to '100%',
            easing: 'easeInOutQuad',
          })
        }

      }
    })
  });

  constructor() {

   }

  ngOnInit(): void {

  }
  ngAfterViewInit():void {
    var target1 = document.querySelector('.atarget1');
    var target2 = document.querySelector('.atarget2');
    var target3 = document.querySelector('.atarget3');
    if(target1) {
      this.observe.observe(target1);
    }
    if(target2) {
      this.observe.observe(target2);
    }
    if(target3) {
      this.observe.observe(target3);
    }

  }


}
