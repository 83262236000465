<section class="wrapper">
  <div class="count-wrapper wrapper">
    <div class="info-title boxtext">
     <span>Experience</span>
    </div>
    <div class="contain-wrapper wrapper">
      <div class="box-item wrapper" *ngFor="let item of infoget; index as i;">
        <div class="title boxtext">
          {{item.title}}
        </div>
        <div class="image">
          <img [src]="item.ico" [alt]="item.title">
        </div>
        <div class="count boxtext">
          {{item.count+'+'}}
        </div>
      </div>
    </div>
  </div>
</section>

