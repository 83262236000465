<mat-list >
    <mat-list-item >
      <a style="text-decoration: none;color:antiquewhite;"  href="{{baseurl}}">
        <mat-icon class="mat-18">home</mat-icon>
        Home
      </a>
    </mat-list-item>
      <mat-list-item>
        <a style="text-decoration: none;color:antiquewhite;"  href="{{baseurl}}/login">
          <mat-icon class="mat-18">login</mat-icon>
          Login
        </a>
      </mat-list-item>
      <mat-list-item>
        <a style="text-decoration: none;color:antiquewhite;"  href="{{baseurl}}/register">
          <mat-icon class="mat-18">how_to_reg</mat-icon>
          Register
        </a>
      </mat-list-item>
</mat-list>

