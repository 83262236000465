import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
declare var anime:any;
@Component({
  selector: 'app-count-cust',
  templateUrl: './count-cust.component.html',
  styleUrls: ['./count-cust.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountCustComponent implements OnInit {
  infoget = [
    {
      title:'Project Done',
      count: 545,
      ico: '/assets/project-completed-icon.svg'
    },
    {
      title:'Happy Clients',
      count:300,
      ico:'/assets/happy-customer-icon.svg'
    },
    {
      title:'Years Of Experience',
      count:3,
      ico:'/assets/favorite-date-calendar-icon.svg'
    },
    {
      title:'Qualified Staff',
      count:7,
      ico:'/assets/corporate-user-icon.svg'
    },
    {
      title:'Active Publisher',
      count:450,
      ico:'/assets/document-application-icon.svg'
    }
  ];
  constructor() { }
  observe = new IntersectionObserver((entries)=>{
    entries.map(entry => {
      if(entry.isIntersecting) {
        var count = entry.target.textContent ?entry.target.textContent:'0' ;
        anime({
          targets: entry.target,
          innerHTML: [0, parseInt(count)],
          easing: 'linear',
          round: 1// Will round the animated value to 1 decimal
        });
      }
    })
  });

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    var target = document.querySelectorAll('.count');
    if(target) {
      target.forEach(x=>{
        this.observe.observe(x);
      })
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.observe.disconnect;
  }
}
