import { NgModule, Directive } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MaterialimportModule } from './materialimport/materialimport.module';
import { HeaderComponent } from './partComp/header/header.component';
import { FooterComponent } from './partComp/footer/footer.component';
import { NavlinksComponent } from './partComp/navlinks/navlinks.component';
import { environment } from 'src/environments/environment';
import { InfoboxComponent } from './partComp/infobox/infobox.component';
import { WhatsappChatComponent } from './partComp/whatsapp-chat/whatsapp-chat.component';
import { AscrollDirective } from './Directives/ascroll.directive';
import { HappyCasComponent } from './partComp/happy-cas/happy-cas.component';
import { CountCustComponent } from './partComp/count-cust/count-cust.component';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavlinksComponent,
    InfoboxComponent,
    AscrollDirective,
    WhatsappChatComponent,
    HappyCasComponent,
    CountCustComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MaterialimportModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [

  ],
  bootstrap: [AppComponent],
  exports: [
    HappyCasComponent
  ]
})
export class AppModule { }
