<section class="box">
  <div class="box-header">
    <div class="nav-wrapper">
      <div class="logo">
        <img [src]="env.svg" [alt]="env.title" height="100" width="100">
      </div>
      <app-navlinks></app-navlinks>
    </div>
    <div class="box-full wrapper">
      <div class="title" #gettitle>
        <span>{{title}}</span>
      </div>
      <div class="subt">
        <span>Share Your Ideas With The Work</span>
      </div>
    </div>
  </div>
  <button mat-icon-button class="downbtn" (click)="scrollpage()"><mat-icon class="mat-18">expand_more</mat-icon></button>
</section>
