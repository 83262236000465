import { NgModule } from '@angular/core';
import { MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon'
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card'
import { MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import { MatTabsModule } from "@angular/material/tabs";



const materialMap:any[] = [
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatMenuModule,
  MatDividerModule,
  MatTabsModule
];


@NgModule({
  exports: [
    materialMap
  ]
})
export class MaterialimportModule { }
