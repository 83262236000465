export const environment = {
  production: true,
  title: 'Vikas-Solution',
  domain:'vikassolution.com',
  baseUrl : `https://affiliate.vikassolution.com`,
  svg:'/assets/vikashsolution.svg',
  png:'/assets/vikashsolution.png',
  client : {
    owner: 'Vikash',
    mobile: '8561838376',
    email: 'admin@vikassolution.com',
    address: [
      'Jaipur',
      'Rajasthan',
      'India'
    ]
  }
};
