<section>
  <div id="footer-box">
    <div id="company">
      <div class="address">
        <mat-card class="starget box_1">
            <mat-card-header class="cardHeader">
                <mat-card-title  >Office</mat-card-title>
            </mat-card-header>
            <mat-card-content >
               <p *ngFor="let add of info.address; index as i">
                 {{add}}
               </p>
            </mat-card-content>
        </mat-card>
      </div>
      <div class="contact" >
        <mat-card class="starget box_2">
          <mat-card-header class="cardHeader"  >
              <mat-card-title>Contact Us</mat-card-title>
          </mat-card-header>
          <mat-card-content style="gap: 0.5rem;display: flex;flex-direction: column;justify-content: center;align-items: baseline;">
            <i class="fa-solid fa-envelope-circle-check" style="font-size: 1.5em;"></i> {{info.email}} <br>
            <i class="fa-brands fa-whatsapp" style="font-size: 1.5em;"></i>  +91 {{info.mobile}}<br>
            <i class="fa-brands fa-telegram" style="font-size: 1.5em;"></i> t.mychannel.com
          </mat-card-content>
      </mat-card>
      </div>
    </div>
    <!-- <div id="social">
      <mat-list dense class="listgroup">
          <mat-list-item class="list" *ngFor="let item of social" [ngStyle]="{'backgroundColor':item.background}">
            <i [class]="item.ico" style="font-size: 1.5rem; color: #ffffff;" ></i>
              <a mat-line [href]="item.url" style="font-size: 1.5em;">{{item.name}}</a>
          </mat-list-item>
      </mat-list>
    </div> -->
    <div id="promo">
      <span>Copyright 2022 © {{domain}}</span>
    </div>
  </div>
</section>
