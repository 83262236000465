import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navlinks',
  templateUrl: './navlinks.component.html',
  styleUrls: ['./navlinks.component.scss']
})
export class NavlinksComponent implements OnInit {
  baseurl =environment.baseUrl;
 links = [
  {name: 'Home',nav:'/',ico:'home'},
  {name: 'Login', nav:'/login',ico: 'login'},
  {name: 'Register', nav:'/register',ico:'logout'}
 ]
  constructor() {

   }

  ngOnInit(): void {
  }

}
