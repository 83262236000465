import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-happy-cas',
  templateUrl: './happy-cas.component.html',
  styleUrls: ['./happy-cas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HappyCasComponent implements OnInit {
items = [
  {
  title: 'Affiliate Marketing',
  img:'/assets/affiliate.svg',
  text:'We will help you get the right exposure to few of the best Affiliate Networks and publishers all across India. Let our performance team manage the entire account ensuring best industry practices, tools to measure and optimize campaign performance on a real-time basis.'
  },
  {
    title: 'Lead Generation',
    img:'/assets/Lead Generation.svg',
    text:'In marketing, lead generation is the initiation of consumer interest or enquiry into products or services of a business. A lead is the contact information and in some cases, demographic information of a customer who is interested in a specific product or service.'
  },
  {
    title: 'Marchant Onboarding',
    img:'/assets/Marchant Onboarding.svg',
    text:'User acquisition (often shortened to UA) is the act of gaining new users for an app, platform, or other service. On mobile, user acquisition is a strategy designed around generating installs, usually achieved by advertising campaigns and promotional offers.'
    }

];
  constructor() { }


  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }
}
