import { environment } from 'src/environments/environment';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
declare var anime:any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  observer = new IntersectionObserver((entries)=> {
    entries.map((entry)=> {
        if(entry.isIntersecting) {

          if(entry.target.classList.value.includes('box_1')) {
            var rwidth = '-' + entry.boundingClientRect.right;
            anime({
              targets: entry.target,
              translateX: [rwidth,0],
              opeacity:[0,1],
              duration: 900,
              delay:500,
              easing: 'easeInOutSine'
            })
          }
          if(entry.target.classList.value.includes('box_2')) {
            var lwidth = -(entry.boundingClientRect.left-window.innerWidth);
            anime({
              targets: entry.target,
              translateX: [lwidth,0],
              opeacity:[0,1],
              duration: 900,
              delay:1000,
              easing: 'easeInOutSine'
            })
          }
        }
    })
  })
  info = environment.client;
  title = environment.title;
  domain = environment.domain;
 social = [
  {name:'Feceook',url:'www.facebook.com',ico:'fa-brands fa-facebook',background:'#1778F2'},
  {name:'Instagram',url:'www.instagram.com',ico:`fa-brands fa-instagram`,background:'#A93493'},
  {name:'Twitter',url:'www.twitter.com',ico:'fa-brands fa-twitter',background:'#1C9CEA'},
  {name:'Telegram',url:'www.telegram.com',ico:'fa-brands fa-telegram',background:'#0084C6'},
]
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    var target1 = document.querySelector('.box_1');
    var target2 = document.querySelector('.box_2');
    if(target1) {
      this.observer.observe(target1);
    }
    if(target2) {
      this.observer.observe(target2);
    }
  }
  ngOnDestroy(): void {
    this.observer.disconnect
  }
}
