import  * as $  from 'jquery';
import { environment } from './../../../environments/environment';
import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

declare var anime:any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit , AfterViewInit,OnDestroy {
  ssize!:string;
  env = environment;
title =environment.title;
titles = environment.title.split(' ');
observe = new IntersectionObserver((entries:any)=>{
  entries.forEach((x:IntersectionObserverEntry) => {
    if(x.isIntersecting) {
      const el = document.querySelector('.box-full .title');

        anime({
          targets: el ,
          scale: [4,1],
          opacity: [0,1],
          translateZ: 0,
          easing: "easeOutExpo",
          duration: 2000,
          delay: (el: any, i: number) => 100*i
        })

    }
  })
},{

});
@ViewChild('gettitle') gtitle!:ElementRef<HTMLElement>;
  constructor(private elm:ElementRef) {}
  ngOnDestroy(): void {
    this.observe.disconnect
  }
  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    if(this.gtitle.nativeElement) {
      this.observe.observe(this.gtitle.nativeElement)
    }

  }
  scrollpage() {
    var target = document.querySelector('.section-wrapper');
    var winh = window.innerHeight;
    if(target) {
      target.scrollTo({
        top:winh,
        behavior:'smooth'
      })
    }
  }

}
