<section>
  <ng-container>
    <div class="contain-wrapper wrapper">
      <div class="ourservice">
        <span>Our Services</span>
      </div>
        <mat-tab-group color="primary" backgroundColor="accent">
          <mat-tab [label]="item.title" *ngFor="let item of items">
            <div class="containt-wrapper wrapper">
              <div class="tab-image">
                <img [src]="item.img" [alt]="item.title">
              </div>
              <div class="tab-text">
                <span>{{item.text}}</span>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

      </div>
  </ng-container>
</section>
